import {
  eachDayOfInterval,
  endOfWeek,
  format,
  formatDistance,
  type Locale,
  startOfWeek,
  addMinutes,
} from 'date-fns'
import { enUS, fr } from 'date-fns/locale'
import { formatInTimeZone } from 'date-fns-tz'

export function dateRangeToPeriod(
  start_datetime: string,
  end_datetime: string
): string {
  const start: Date = new Date(start_datetime)
  const end: Date = new Date(end_datetime)
  let noon: Date = new Date(start_datetime)
  noon.setHours(12, 0, 0, 0)
  if (start.getTime() <= noon.getTime() && end.getTime() <= noon.getTime()) {
    return 'morning'
  } else if (
    start.getTime() >= noon.getTime() &&
    end.getTime() >= noon.getTime()
  ) {
    return 'afternoon'
  } else {
    return 'all_day'
  }
}

export function dateStringForDate(date: Date): string {
  let month: string = (date.getUTCMonth() + 1).toString()
  if (month.length === 1) {
    month = '0' + month
  }
  let day: string = date.getUTCDate().toString()
  if (day.length === 1) {
    day = '0' + day
  }

  return date.getUTCFullYear() + '-' + month + '-' + day
}

export function dateTimesForPeriodAndDay(
  day: string,
  period: string
): [Date, Date] {
  let startDateTime: Date = new Date(day)
  startDateTime.setUTCHours(0, 0, 0, 0)

  let endDateTime: Date = new Date(startDateTime)
  endDateTime.setUTCHours(23, 59, 59, 999)

  if (period === 'afternoon') {
    startDateTime.setUTCHours(12)
  }

  if (period === 'morning') {
    endDateTime.setUTCHours(12, 0, 0, 0)
  }

  return [startDateTime, endDateTime]
}

export const dateFnsLocaleFromI18nLocale = (locale: string): Locale => {
  switch (locale) {
    case 'fr':
      return fr
    case 'en':
    default:
      return enUS
  }
}

export const weekDays = ({
  locale,
  dateFormat,
}: {
  locale: Locale
  dateFormat?: string
}): string[] => {
  const now = new Date()
  const start = startOfWeek(now, { locale })
  const end = endOfWeek(now, { locale })

  return eachDayOfInterval({ start, end }).map((day) =>
    format(day, dateFormat ?? 'EEEE', { locale })
  )
}

export const dateFormatForAPI = (
  date: Date,
  format_str: string = "yyyy-MM-dd'T'HH:mm:ss"
): string => {
  return format(addMinutes(date, date.getTimezoneOffset()), format_str)
}

export function minDate() {
  let minDate = new Date()

  minDate.setUTCHours(0, 0, 0, 0)

  return minDate
}

export function maxDate(maxWeeks: number) {
  const weeksInAdvance = maxWeeks || 1
  const advanceTime = weeksInAdvance * 7 * 86400 * 1000

  let maxDate = new Date(Date.now() + advanceTime)

  maxDate.setUTCHours(0, 0, 0, 0)

  return maxDate
}

export const timeDistance = (date: Date, now?: Date = new Date()) => {
  return formatDistance(date, now, {
    addSuffix: true,
  })
}
